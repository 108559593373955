@import url("https://fonts.googleapis.com/css2?family=Arsenal:ital,wght@0,400;0,700;1,400;1,700&family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-primary  overflow-x-hidden;
  }
  .btn-primary {
    @apply uppercase font-primary text-white bg-red px-6 py-3 font-semibold flex items-center transition duration-300 hover:scale-105;
  }

  .red-badge {
    @apply px-2 py-1 bg-red text-white font-secondary text-[14px] font-semibold uppercase inline-block;
  }

  h2 {
    @apply md:text-[40px] text-[35px] font-primary uppercase text-[#010203] font-semibold leading-none;
  }

  p {
    @apply font-text;
  }
}

/* CUSTOM CSS */
.hero_section {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)),
    url("/public/image/hero-img.jpeg") no-repeat center;
  background-size: cover;
}

.btn-primary-outline {
  font-family: "Barlow Condensed", sans-serif;
  color: #e31c23;
  background-color: transparent;
  font-size: 14px;
  padding: 10px 22px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s linear;
  text-transform: uppercase;
  border: 1px solid #e31c23;
}

.btn-primary-outline:hover {
  background-color: #e31c23;
  color: white;
}

.swiper-button-prev,
.swiper-button-next {
  display: none !important;
}

/* STORE SECTION */
.gradient {
  background-image: radial-gradient(
    circle at center,
    rgb(255, 27, 56) 0%,
    rgb(156, 8, 27) 100%
  );
}

.about-gradient {
  background-image: radial-gradient(circle at center, #ff1b38, #9c081c);
  background-size: cover; /* Optionally add this to cover the entire element */
}

.gallery-img-one {
  clip-path: polygon(0% 0%, 100% 0%, 70% 100%, 0% 100%, 0% 100%);
}

.gallery-img-four{
  width: 100% !important;
  object-fit: cover;
  clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 100%);
}

.gallery-img-five {
  clip-path: polygon(8% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 100%);
}

.gallery-img-two ,
.gallery-img-three,
.img-four-two {
  transform: skew(-8deg);
}

.gallery-images{
  display: flex;
  gap: 10px;
}

.gallery-images img{
  width: 100%;
}

.gallery-flex1{
  display: flex;
  gap: 10px;
}

.gallery-images-small{
  display: flex;
  gap: 10px;
}

.gallery-images-small img{
  width: 100%;
}

.gallery-flex1{
  display: flex;
  gap: 10px;
}




.program_gradient {
  background-image: linear-gradient(
    to left,
    rgba(227, 28, 35, 1),
    rgba(125, 15, 19, 1)
  );
}

.hospitality-gradient {
  background-image: linear-gradient(to bottom, #c20d25ef, #000);
}

.community-gradient {
  background-image: linear-gradient(to bottom, #c20d25ef, #ff8080);
}

.join-img {
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
  width: 100%;
}

.team-card {
  clip-path: polygon(20% 0%, 100% 0%, 84% 100%, 0% 100%, 0% 100%);
}

.team-card-two {
  clip-path: polygon(24% 0%, 100% 0%, 80% 100%, 0% 100%, 0% 100%);
}

.contact-btn {
  clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%, 0% 100%);
}

.model-content {
  clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%, 0% 100%);
}

/* STORE SECTION */
/* CUSTOM CSS */
.store_section {
  background: linear-gradient(to right, #dc1731e9 5%, #ffffff00 70%),
    url("/public/image/store/header-bg.jpeg") no-repeat center;
  background-size: cover;
}
.swiper-pagination {
  display: none !important;
}


@media (max-width: 998px) {
  .model-content {
    clip-path: none;
  }
 
}
@media (max-width: 576px) {
  .join-img {
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 60%);
  }
  .swiper-pagination {
    display: block !important;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0rem !important;
  }

  .swiper-pagination-bullet {
    background-color: rgba(255, 255, 255, 0.6) !important;
  }

  .swiper-pagination-bullet-active {
    background-color:#fff !important;
  }

}
